import React from 'react';
import './../css/Middle_timovi.css';

class Middle_timovi extends React.Component {

  render() {
    return (
      <div className="Middle_timovi">
          
          <div className="naslov_timovi">
              <div1> </div1>
              <div> {this.props.title} </div>
              <div1> </div1>
          </div>

          <div className="clanovi_timovi">
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
          </div>

          <div className="clanovi_timovi">
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
          </div>

          <div className="clanovi_timovi">
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
              <div className="clan_t"> 
                  <div> <img src="/proba.jpg" alt="clan" /> </div>
                  <div> Nikola Jovanovic </div>
              </div>
          </div>
          
      </div>
      );
  }

}

export default Middle_timovi;