import React from 'react';
import './../css/Partneri.css';
import Header from './Header';
import Footer from './Footer';

class Partneri extends React.Component {

  render() {
    return (
      <div className="Partneri">
          <Header />
          <Footer />
      </div>
      );
  }

}

export default Partneri;