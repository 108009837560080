import React, { Component } from 'react';
import '../css/MiddleONama.css';

class MiddleONama extends Component {

    render(){
        return (
            <div className="middle_onama">
                <div className="onama_comp">
                <img src="./logo4.png" className="repsus" alt="onama"/>

                    <div className="tekstProjekti">
                        <span style={{color: "#71cbc9"}}>„Studenti Ekonomskog fakulteta - SEFA“</span> je studentska organizacija sa Ekonomskog fakulteta u Beogradu, osnovana  2015. godine.
                        <br />
                        <br />
                        Moto naše organizacije <span style={{color: "#71cbc9"}}>„Prave stvari na pravi način”</span>, najbolje moguće opisuje njenu misiju i cilj postojanja, a to je da pruži studentima mogućnost da kroz razne vannastavne aktivnosti, unaprede svoja znanja i veštine, sa fokusom na timski duh, humanost, efikasnost, kolegijalnost, kao i na zajedništvo među studentima i studentskim organizacijama.
                        <br />
                        <br />
                        <span style={{color: "#71cbc9"}}>SEFA</span> je struktuirana tako da zadatke i ideje u okviru projekata članovi realizuju podeljeni u timove (sektore) koji su tipični za jedno preduzeće, poput sektora komunikacija u kom se nalaze PR i marketinške aktivnosti, zatim sektor Sponzorstva i Prodaje, sektor Upravljanja Projektima i sektor Upravljanja Ljudskim Resursima. Na ovaj način svako ima šansu da usmeri svoj talenat, interesovanje i da kroz praktičan rad, stekne iskustvo i  nauči nešto novo iz oblasti prema kojoj gaji afinitete.
                        <br />
                        <br />
                        Organizacija od svog nastanka vodi računa o svojim članovima, omogućavajući im program mentorstva kako bi postigli što bolji uspeh na fakultetu i savladali prepreke na koje nailaze tokom studiranja kao i razne edukacije kako bi unapredili svoja znanja i veštine. Osim što članovi organizacije produktivno rade i trude se da sebi i svojim kolegama poboljšaju studentski život kroz brojne navedene događaje, oni se svakodnevno druže i jačaju timski duh kako bi funkcionisali kao porodica. Karaoke, beer pong, krstarenje, izleti  i svirke samo su neke od aktivnosti koje su zadužene za zabavu i druženje članova.
                        <br />
                        <br />
                        SEFA broji preko 70 članova koji svojim zalaganjem, učestvuju u organizovanju konferencija, sportskih turnira, takmičenja i mnogih drugih manifestacija edukativnog, humanitarnog i zabavnog karaktera, kako bi studentima pospešili produktivnost i omogućili im da na interaktivan način steknu nova znanja, veštine i poznanstva.
                        <br />
                        <br />
                        <span style={{color: "#71cbc9"}}>Alumni klub</span> je ponos naše organizacije i broji preko 50 članova koji su diplomirali na Ekonomskom fakultetu i danas se nalaze na raznim pozicijama državnih institucija, velikih korporacija, a posebno smo ponosni na one koji su se odlučili da nakon studija se oprobaju kao mladi preduzetnici! 
                        <br />
                        <br />
                        Više o aktivnostima i projektima naše organizacije možete pročitati u posebnim odeljcima u okviru sajta.
                    </div>
                </div>
                <div className="slider_img_onama">
                    <div className="img1_onama">
                    </div>
                    <div className="img2_onama">
                    </div>
                    <div className="img3_onama">
                    </div>
                </div>
                

                <div className="onama_response">
                <img src="./logo4.png" className="repsus" alt="onama"/>
                
                <div className="tekstProjekti">
                <span style={{color: "#71cbc9"}}>„Studenti Ekonomskog fakulteta - SEFA“</span> je studentska organizacija sa Ekonomskog fakulteta u Beogradu, osnovana  2015. godine.
                <br />
                <br />
                Moto naše organizacije <span style={{color: "#71cbc9"}}>„Prave stvari na pravi način”</span>, najbolje moguće opisuje njenu misiju i cilj postojanja, a to je da pruži studentima mogućnost da kroz razne vannastavne aktivnosti, unaprede svoja znanja i veštine, sa fokusom na timski duh, humanost, efikasnost, kolegijalnost, kao i na zajedništvo među studentima i studentskim organizacijama.
                <br />
                <br />
                <img src="/Onama1.jpg" alt="onama" style={{width:"100%", paddingBottom:"10%"}}/>
                <br />
                <br />
                <span style={{color: "#71cbc9"}}>SEFA</span> je struktuirana tako da zadatke i ideje u okviru projekata članovi realizuju podeljeni u timove (sektore) koji su tipični za jedno preduzeće, poput sektora komunikacija u kom se nalaze PR i marketinške aktivnosti, zatim sektor Sponzorstva i Prodaje, sektor Upravljanja Projektima i sektor Upravljanja Ljudskim Resursima. Na ovaj način svako ima šansu da usmeri svoj talenat, interesovanje i da kroz praktičan rad, stekne iskustvo i  nauči nešto novo iz oblasti prema kojoj gaji afinitete.
                <br />
                <br />
                <img src="/Onama2.jpg" alt="onama" style={{width:"100%", paddingBottom:"10%"}}/>
                <br />
                <br />
                Organizacija od svog nastanka vodi računa o svojim članovima, omogućavajući im program mentorstva kako bi postigli što bolji uspeh na fakultetu i savladali prepreke na koje nailaze tokom studiranja kao i razne edukacije kako bi unapredili svoja znanja i veštine. Osim što članovi organizacije produktivno rade i trude se da sebi i svojim kolegama poboljšaju studentski život kroz brojne navedene događaje, oni se svakodnevno druže i jačaju timski duh kako bi funkcionisali kao porodica. Karaoke, beer pong, krstarenje, izleti  i svirke samo su neke od aktivnosti koje su zadužene za zabavu i druženje članova.
                <br />
                <br />
                <img src="/Onama3.jpg" alt="onama" style={{width:"100%", paddingBottom:"10%"}}/>
                <br />
                <br />
                SEFA broji preko 70 članova koji svojim zalaganjem, učestvuju u organizovanju konferencija, sportskih turnira, takmičenja i mnogih drugih manifestacija edukativnog, humanitarnog i zabavnog karaktera, kako bi studentima pospešili produktivnost i omogućili im da na interaktivan način steknu nova znanja, veštine i poznanstva.
                <br />
                <br />
                <img src="/Onama4.jpg" alt="onama" style={{width:"100%", paddingBottom:"10%"}}/>
                <br />
                <br />
                <span style={{color: "#71cbc9"}}>Alumni klub</span> je ponos naše organizacije i broji preko 50 članova koji su diplomirali na Ekonomskom fakultetu i danas se nalaze na raznim pozicijama državnih institucija, velikih korporacija, a posebno smo ponosni na one koji su se odlučili da nakon studija se oprobaju kao mladi preduzetnici! 
                <br />
                <br />
                Više o aktivnostima i projektima naše organizacije možete pročitati u posebnim odeljcima u okviru sajta.    
                </div>
            </div>
            <div className ="baner">
                    <img src="/sefa_baner.jpg" alt="baner" className="baner_img"/>
                </div>
            </div>
        )
    }
}

export default MiddleONama;