import React, { Component } from 'react';
import '../css/Middle_projekti.css';

class MiddleProjekti extends Component {

    render(){
        return (
            <div className="middle_repsus">
                <div className="repsus_comp">
                <img src="/repsus-logo.png" className="repsus" alt="repsus"/>
                
                <div className="tekstProjekti">
                <span id="repsusTekst">„Regionalni privredni susreti studenata – REPSUS”</span> je svojevrstan simbol organizacije od samog nastanka. 
                Projekat je osmišljen tako da studenti koji učestvuju u projektu steknu znanja i budu u toku sa aktuelnostima i trendovima u ekonomiji. 
                Organizovan je u vidu konferencije na kojoj učestvuju eminentne ličnosti iz sveta ekonomije. 
                Do sada repsus je održavan na različitim mestima (Zlatibor 2016, Crowne Plaza 2017, Ekonomski fakultet 2018. i 2019. godine). <br/><br/>

                Studenti su do sada imali prilike da slušaju govornike na sledeće teme:<br/>
                <ul>
                <li>Dostupnost i kako doći do fondova stranog kapitala – Rade Joksimović</li>
                <li>Razvoj online trgovine u Srbiji – Vladimir Nikolić, Limundo i Kupindo</li>
                <li>Moj put do uspeha – Milenko Škarić, SKY MUSIC CORPORATON</li>
                <li>Investiciono bankarstvo – Upravljanje rizikom i kapitalom u bankama – Zoran Petrović, Raiffaisen</li>
                <li>Budućnost bankarskog sektora – Telenor i Sber banka</li>
                <li>Inovacije u obrazovanju, preduzetništvu i privredi – Hasan Hanić</li>
                <li>StartUp Srbija - Seezer, Evoke, Miracle Dojo…</li>
                <li>Rizik je iluzija – Rade Joksimović</li>
                <li>Mona - Male tajne našeg uspeha – Tomislav Momirović</li>
                <li>Obrazovanjem do uspešne karijere – Srđan Janićijević, Mokrogorska škola menadžmenta</li>
                <li>Karijera ili mit(o) – Aleksandar Marušić, IKEA</li>
                <li>Nije svejedno kakve izbore pravite – Ovation BBDO za Nectar</li>
                <li>Put do najuspešnijeg StartUp-a – Kosara Dangić Melentijević, Kabinet Brewery</li>
                <li>Doprinosi intereneta savremenom poslovanju – Vladimir Miletić i Anđelka Ducić</li>
                <li>Advertajzin, čemu to služi a uz to i radi? – Ovation BBDO</li>
                </ul>

                Partneri konferencije bile su brojne kompanije poput:<br/>
                <ul>
                <li>Esensa, Jaffa, Chips Way, Na Eks, Chi, Dunav osiguranje, SKY MUSIC, EXIT – 2016.</li>
                <li>Tuborg, Nelt, Jaffa, Logo, Konzilijum, Suprema Lab, Rosa, Banca Intesa, Crowne Plaza – 2017.</li>
                <li>Ekonomski fakultet, Nectar, Jaffa, Equilibrio – 2018.</li>
                <li>Ekonomski fakultet, Ball, Silbo, Frikom, Nectar, Booster, Heba, Munchmallow, GDI – 2019.</li>
                </ul>
                </div>
                <div className="slider_img_repsus">
                    <div className="img1_repsus">
                    </div>
                    <div className="img2_repsus">
                    </div>
                    <div className="img3_repsus">
                    </div>
                    <div className="img4_repsus">
                    </div>
                </div>
            </div>
            <div className="repsus_response">
                <img src="/repsus-logo.png" className="repsus" alt="repsus"/>
                
                <div className="tekstProjekti">
                Regionalni privredni susreti studenata – <span id="repsusTekst">REPSUS</span> je svojevrstan simbol organizacije od samog nastanka. 
                Projekat je osmišljen tako da studenti koji učestvuju u projektu steknu znanja i budu u toku sa aktuelnostima i trendovima u ekonomiji. 
                Organizovan je u vidu konferencije na kojoj učestvuju eminentne ličnosti iz sveta ekonomije. 
                Do sada repsus je održavan na različitim mestima (Zlatibor 2016, Crowne Plaza 2017, Ekonomski fakultet 2018 i 2019). <br/><br/>
                <img src="/repsus1.jpg" alt="repsus" style={{width:"100%", paddingBottom:"10%"}}/>
                Studenti su do sada imali prilike da slušaju govornike na sledeće teme:<br/>
                <ul>
                <li>Dostupnost i kako doći do fondova stranog kapitala – Rade Joksimović</li>
                <li>Razvoj online trgovine u Srbiji – Vladimir Nikolić, Limundo i Kupindo</li>
                <li>Moj put do uspeha – Milenko Škarić, SKY MUSIC CORPORATON</li>
                <li>Investiciono bankarstvo – Upravljanje rizikom i kapitalom u bankama – Zoran Petrović, Raiffaisen</li>
                <li>Budućnost bankarskog sektora – Telenor i Sber banka</li>
                <li>Inovacije u obrazovanju, preduzetništvu i privredi – Hasan Hanić</li>
                <li>StartUp Srbija - Seezer, Evoke, Miracle Dojo…</li>
                <li>Rizik je iluzija – Rade Joksimović</li>
                <li>Mona - Male tajne našeg uspeha – Tomislav Momirović</li>
                <li>Obrazovanjem do uspešne karijere – Srđan Janićijević, Mokrogorska škola menadžmenta</li>
                <li>Karijera ili mit(o) – Aleksandar Marušić, IKEA</li>
                <li>Nije svejedno kakve izbore pravite – Ovation BBDO za Nectar</li>
                <li>Put do najuspešnijeg StartUp-a – Kosara Dangić Melentijević, Kabinet Brewery</li>
                <li>Doprinosi intereneta savremenom poslovanju – Vladimir Miletić i Anđelka Ducić</li>
                <li>Advertajzin, čemu to služi a uz to i radi? – Ovation BBDO</li>
                </ul>
                <img src="/repsus2.jpg" alt="repsus" style={{width:"100%", paddingBottom:"10%"}}/>
                Partneri konferencije bile su brojne kompanije poput:<br/>
                <ul>
                <li>Esensa, Jaffa, Chips Way, Na Eks, Chi, Dunav osiguranje, SKY MUSIC, EXIT – 2016.</li>
                <li>Tuborg, Nelt, Jaffa, Logo, Konzilijum, Suprema Lab, Rosa, Banca Intesa, Crowne Plaza – 2017.</li>
                <li>Ekonomski fakultet, Nectar, Jaffa, Equilibrio – 2018.</li>
                <li>Ekonomski fakultet, Ball, Silbo, Frikom, Nectar, Booster, Heba, Munchmallow, GDI – 2019.</li>
                </ul>
                <img src="/repsus3.jpg" alt="repsus" style={{width:"100%"}}/>
                </div>
            </div>

        </div>

        );
    }
}

export default MiddleProjekti;