import React, { Component } from 'react';
import '../css/MiddleBezStraha.css';

class MiddleBezStraha extends Component {

    render(){
        return (
            <div className="middle_repsus">
                <div className="repsus_comp">
                <h2 id="naslovBezStraha">BEZ STRAHA</h2>
                
                <div className="tekstProjekti">
                <span id="repsusTekst">SEFA</span> je oduvek delovala odgovorno, a jedan od najboljih primera je ideja članica SEFA-e da organizujući dvočasovnu panel diskusiju razbiju tabu temu svakodnevnice, a to je <span id="repsusTekst">anskioznost.</span><br/><br/>

                Projekat je osmišljen tako da studenti kroz dvočasovnu panel diskusiju nauče da se nose sa strahom i svakodnevnim stresnim situacijama, da se ne osećaju loše što imaju takva osećanja i da slobodno razgovaraju o tome, zatim da nauče na koji način mogu da izgrade samopouzdanje i da izađu iz svoje komforne zone, kako da ih strah ne ograničava u postizanju uspeha i da se izbore za sebe u svetu biznisa, kako da odrede svoje prioritete i na koji način da ih stave ispred tuđih, <span id="repsusTekst">kako da pobede anksioznost!</span>
                <br/><br/>
                Neka od pitanja na osnovu kojih se razgovara o ovoj temi su: <br/>
                Šta je to što osećamo i kakav je to strah? <br/>
                Kako se izboriti sa anksioznošću uz različite tehnike/savete? <br/>
                Da li je razgovor o anskioznošću nešto o čemu smemo da razgovaramo u društvu?<br/>
                Ideja projekta je da se predstavi problem straha iz ugla studenta, poznate ličnosti, poslovnih ljudi i psihologa, koji treba da zaokruži priču stručnom analizom i savetima.
                <br/><br/>
                Učesnici prve panel diskusije u Profesorskoj sali na Ekonomskom fakultetu, maja 2019. bile su:<br/>
                <ul>
                <li>Aleksandra Saša Simović – Diplomirani novinar i PR menadžer,</li>
                <li>Teodora Miljković – PR menadžer i influenser,</li>
                <li>Sandra Bijelac – Psihoterapeut,</li>
                <li>Stella Karl Ćosić – Executive & Leadership coach.</li>
                </ul>
                <div className="pravaStvar_baner">
                    <img src="/bezStraha1.png" alt="baner" />
                </div>
                </div>
                <div className="slider_img_bezStraha">
                    <div className="img1_bezStraha">
                    </div>
                    <div className="img2_bezStraha">
                    </div>
                    <div className="img3_bezStraha">
                    </div>
                </div>
            </div>
            <div className="repsus_response">
                <h2 id="naslovBezStraha">BEZ STRAHA</h2>
                
                <div className="tekstProjekti">
                <span id="repsusTekst">SEFA</span> je oduvek delovala odgovorno, a jedan od najboljih primera je ideja članica SEFA-e da organizujući dvočasovnu panel diskusiju razbiju tabu temu svakodnevnice, a to je <span id="repsusTekst">anskioznost.</span><br/><br/>

                <img src="/bezStraha1.jpg" alt="bezStraha" style={{width:"100%", paddingBottom:"10%"}}/>
                Projekat je osmišljen tako da studenti kroz dvočasovnu panel diskusiju nauče da se nose sa strahom i svakodnevnim stresnim situacijama, da se ne osećaju loše što imaju takva osećanja i da slobodno razgovaraju o tome, zatim da nauče na koji način mogu da izgrade samopouzdanje i da izađu iz svoje komforne zone, kako da ih strah ne ograničava u postizanju uspeha i da se izbore za sebe u svetu biznisa, kako da odrede svoje prioritete i na koji način da ih stave ispred tuđih, <span id="repsusTekst">kako da pobede anksioznost!</span>
                <br/><br/>
                <img src="/bezStraha6.jpg" alt="bezStraha" style={{width:"100%", paddingBottom:"10%"}}/>
                Neka od pitanja na osnovu kojih se razgovara o ovoj temi su: <br/>
                Šta je to što osećamo i kakav je to strah? <br/>
                Kako se izboriti sa anksioznošću uz različite tehnike/savete? <br/>
                Da li je razgovor o anskioznošću nešto o čemu smemo da razgovaramo u društvu?<br/>
                Ideja projekta je da se predstavi problem straha iz ugla studenta, poznate ličnosti, poslovnih ljudi i psihologa, koji treba da zaokruži priču stručnom analizom i savetima.
                <br/><br/>
                <img src="/bezStraha3.jpg" alt="bezStraha" style={{width:"100%", paddingBottom:"10%"}}/>
                Učesnici prve panel diskusije u Profesorskoj sali na Ekonomskom fakultetu, maja 2019. bile su:<br/>
                <ul>
                <li>Aleksandra Saša Simović – Diplomirani novinar i PR menadžer,</li>
                <li>Teodora Miljković – PR menadžer i influenser,</li>
                <li>Sandra Bijelac – Psihoterapeut,</li>
                <li>Stella Karl Ćosić – Executive & Leadership coach.</li>
                </ul>
                <img src="/bezStraha2.jpg" alt="bezStraha" style={{width:"100%", paddingBottom:"10%"}}/>
                <div className="pravaStvar_baner">
                    <img src="/bezStrahaTel.png" alt="baner" />
                </div>
                </div>
            </div>

        </div>

        );
    }
}

export default MiddleBezStraha;